import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../../containers/layout';
import { DetailedListingQuery } from '../../../graphql-types';
import PortableText from '../../components/portableText';
import ImageGrid from '../../components/ImageGrid';
import TechnologySection from '../../components/projects/TechnologySection';

interface Props {
  data: DetailedListingQuery;
}

const DetailedProject: React.FC<Props> = ({ data }) => {
  return (
    <Layout>
      <div className="sm:mt-12 lg:mt-32 lg:mx-8 xl:mx-40 mx-4">
        <div className="flex flex-col md:grid md:grid-flow-col md:grid-cols-12 md:gap-16">
          <div className="md:col-span-5 flex flex-col">
            <div className="mb-4">
              <h1>{data.sanityProject?.name}</h1>
              {data.sanityProject?.projectType && (
                <p className="font-light text-gray-600">
                  {data.sanityProject?.projectType.name}{' '}
                  {data.sanityProject?.projectType.linkName && (
                    <span>
                      -{' '}
                      <a
                        className="link"
                        href={data.sanityProject?.projectType.link}
                        target="_blank"
                      >
                        {data.sanityProject?.projectType.linkName}
                      </a>
                    </span>
                  )}
                </p>
              )}
            </div>
            <PortableText
              blocks={data.sanityProject?._rawLongDescription ?? []}
            />
            <TechnologySection
              technologies={data.sanityProject?.technologies ?? []}
            />
          </div>
          <div className="md:col-span-7">
            {data.sanityProject?.imageGallery &&
              data.sanityProject.imageGallery?.length > 0 && (
                <ImageGrid
                  images={data.sanityProject?.imageGallery}
                  imageDescription={data.sanityProject?.imagesDescription}
                />
              )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query DetailedListing($id: String) {
    sanityProject(id: { eq: $id }) {
      id
      name
      _rawLongDescription
      cardColor {
        hex
      }
      imageGallery {
        image {
          ...SanityImageWithPreview
        }
        screenshot
      }
      imagesDescription
      technologies {
        technology {
          name
          url
          logo {
            ...SanityImageWithPreview
          }
        }
      }
      mainImage {
        ...SanityImage
      }
      projectType {
        name
        linkName
        link
      }
    }
  }
`;

export default DetailedProject;
