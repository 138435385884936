import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
interface Props {
  images: any[];
  imageDescription?: string | null;
}
interface ImageProps {
  image: any;
}

const Screenshot: React.FC<ImageProps> = ({ image }) => (
  <div className="h-full w-full max-w-[300px] object-cover m-2">
    <Image className="shadow-stripe rounded-md" {...image} alt="image" />
  </div>
);

const DesktopImage: React.FC<ImageProps> = ({ image }) => {
  return (
    <Image
      className="h-full w-full shadow-stripe rounded-md"
      {...image}
      alt="image"
    />
  );
};

const ImageGrid: React.FC<Props> = ({ images, imageDescription }) => {
  return (
    <div className="block mx-auto w-full h-full box-border">
      <div className="flex flex-col">
        <div className="flex mx-auto -m-2 box-border">
          {images.map((image, index) => {
            if (index < 3) {
              return image.screenshot ? (
                <Screenshot key={index} image={image.image} />
              ) : (
                <DesktopImage key={index} image={image.image} />
              );
            }
          })}
        </div>
        {imageDescription ? (
          <p className="font-light text-gray-600 pt-2">{imageDescription}</p>
        ) : null}
      </div>
    </div>
  );
};

export default ImageGrid;
