import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { motion } from 'framer-motion';

interface Props {
  technologies: any[];
}

const parentMotion = {
  rest: {
    scale: 1,
  },
  hover: { scale: 1.1, cursor: 'pointer' },
  tap: {
    scale: 0.9,
  },
};

const tooltipMotion = {
  rest: {
    scale: 0,
    translateX: '-50%',
    translateY: '-50%',
  },
  hover: {
    scale: 1,
    translateX: '-50%',
    translateY: '-50%',
  },
};

const TechnologySection: React.FC<Props> = ({ technologies }) => {
  return (
    <div className="flex flex-col my-8">
      <h1 className="text-2xl mb-4">Technologies used</h1>
      <div className="flex flex-wrap -m-2">
        {technologies &&
          technologies.map((technologyObj: any) => (
            <motion.a
              href={technologyObj.technology.url}
              target="_blank"
              className="relative shadow-stripe rounded-md p-2 m-2"
              initial="rest"
              whileHover="hover"
              whileTap="tap"
              variants={parentMotion}
            >
              <motion.div
                className="top-0 left-1/2 bg-gray-700 px-2 py-1 rounded-sm absolute opacity-75 -mt-4"
                variants={tooltipMotion}
              >
                <p className="font-bold text-white text-sm whitespace-nowrap">
                  {technologyObj.technology.name}
                </p>
              </motion.div>
              <Image
                className="h-10"
                {...technologyObj.technology.logo}
                alt={`logo-${technologyObj.technology.name}`}
              />
            </motion.a>
          ))}
      </div>
    </div>
  );
};

export default TechnologySection;
